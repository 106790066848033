import { cdnUrl } from "client/config/url";
import { translateFunc } from "client/utils/translateFunc";
import { EasyToUseIcon } from "components/common/Icons/EasyToUseIcon";
import { HeartIcon } from "components/common/Icons/HeartIcon";
import { LightningFastIcon } from "components/common/Icons/LightningFastIcon";
import { PerfectQualityIcon } from "components/common/Icons/PerfectQualityIcon";
import { PrivacyGuaranteedIcon } from "components/common/Icons/PrivacyGuaranteedIcon";
import { WorksAnywhereIcon } from "components/common/Icons/WorksAnywhereIcon";
import { Translate } from "components/common/Translate/Translate";
import Image from "next/image";
import { ContentItem } from "../ContentItem/ContentItem";
import { ContentParent } from "../ContentParent";

export const getCDNIcon = (src: string, alt: string) => (
    <Image src={`${cdnUrl}/icons${src}`} width={30} height={30} alt={alt} />
);

export const ImageResizerContent = () => {
    return (
        <ContentParent>
            <ContentItem
                title={<Translate keyName="content-title-perfect-quality" />}
                description={<Translate keyName="content-perfect-quality" />}
                icon={<PerfectQualityIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-title-fast" />}
                description={<Translate keyName="content-fast" />}
                icon={<LightningFastIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-title-easy" />}
                description={<Translate keyName="content-easy" />}
                icon={<EasyToUseIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-title-works" />}
                description={<Translate keyName="content-works" />}
                icon={<WorksAnywhereIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-title-privacy" />}
                description={<Translate keyName="content-privacy" />}
                icon={<PrivacyGuaranteedIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-title-free" />}
                description={<Translate keyName="content-free" />}
                icon={<HeartIcon />}
            />
        </ContentParent>
    );
};
